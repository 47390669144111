[data-theme=light], [data-theme=dark] {
  --primary-color: var(--green) ;
}

.login-page {
  background-color: #fff;
}
@media (max-width: 768px) {
  .login-page {
    flex-flow: column;
    flex-direction: column-reverse;
    overflow-y: scroll;
  }
}
.login-page div {
  flex: 1 1 50%;
}
.login-page div .page-card {
  box-shadow: unset;
  background: transparent;
}
.login-page div .form-login .btn-login {
  background-color: var(--primary-color);
}
.login-page div .form-login .btn-login:active {
  background-color: var(--primary-color);
}
.login-page div.login-banner {
  padding: 70px;
}
@media (max-width: 768px) {
  .login-page div.login-banner {
    padding: 20px;
  }
}
@media (min-width: 769px) {
  .login-page div.login-banner ul.left-top-list {
    padding-top: 10%;
  }
}
.login-page div.login-banner ul.left-top-list > li {
  padding: 15px;
}
.login-page div.login-banner ul.left-top-list > li a:hover {
  text-decoration: unset;
}
.login-page div.login-banner ul.left-top-list > li a span {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-color);
}
.login-page div.login-banner ul.left-bottom-list {
  margin-top: auto;
}
.login-page div.login-banner ul.left-bottom-list img {
  max-height: 2.5rem;
}
.login-page div.login-banner ul.left-bottom-list button {
  border-radius: 1rem;
}
.login-page .copyright {
  margin-top: auto;
  padding-left: 1rem;
}